<template>
  <div class="flex min-h-full flex-1 flex-col px-3 py-1 md:py-12 lg:px-8" :style="{ height: heightVh, backgroundImage: `linear-gradient(rgba(133,150,197,0.7), rgba(37,26,230,.6)), url(${backgroundUrl})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }">
    <div class="cursor-pointer" @click="$router.push('/')">
      <img class="h-40 m-auto mm:h-32 md:h-[200px]" :src="Logo" alt="Conecta SJB Logo" />
    </div>

    <div class="bg-black/[.09] p-4 border border-slate-200/[.06] rounded-md text-white text-center text-[13px] md:text-lg md:mt-7 md:space-y-5 md:max-w-4xl md:mx-auto phone:text-base phone:mt-3">
      <p>
        Em um mundo onde a economia global é cada vez mais interconectada, as empresas que conseguem formar parceiras estratégicas têm uma
        vantagem significativa onde o poder de conexão entre as empresas não é apenas um facilitador de negócios; é um multiplicador de oportunidades.
      </p>

      <p>
        A Prefeitura de <b>São João da Barra</b> através de iniciativa de criação deste Portal, visa facilitar a conexão entre empresas gerando uma estratégia
        como caminho para o crescimento, inovação e sucesso sustentável.
      </p>

      <p>
        Ao unir forças, compartilhar recursos e alinhar os objetivos, as empresas podem não apenas sobreviver, mas prosperar em um ambiente de
        negócios cada vez mais competitivo e globalizado.
      </p>
    </div>

    <!-- <div class="sm:mt-5 mt-5 md:m-auto md:grid md:gap-20 md:grid-cols-2 -md:content-between md:mt-10 -md:w-fit"> -->
    <div class="sm:mt-5 mt-5 md:flex md:justify-between mx-auto">
      <CustomButton @click="$router.push('/login')" class="sm:h-[120px] flex justify-items-center flex-col items-center">
        <label class="opacity-60">PROCURANDO</label>
        <b>PRODUTOS E SERVIÇOS </b>
        <label>Clique Aqui</label>
      </CustomButton>

      <CustomButton @click="$router.push('/login-supplier')" class="sm:h-[120px] flex justify-items-center flex-col items-center">
        <label class="opacity-60">VOCÊ É UM</label>
        <b>FORNECEDOR DE SERVIÇOS?</b>
        <label>Clique Aqui</label>
      </CustomButton>
    </div>

  </div>

</template>

<script lang="ts" setup>
import backgroundUrl from "~/assets/images/folders/image_background.jpg";
import Logo from "~/assets/images/logo.png";

const heightVh = ref("100vh");
const resolution = ref(0);

const handleResize = () => {
  let vh = 100;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const heightMin = 707;

  if(width < 268){
    setVh(vh + ((268 - width) / 1));
  }else if(height < 180){
    setVh(vh + ((heightMin - height) / 1.2));
  }else if(height < 250){
    setVh(vh + ((heightMin - height) / 1.7));
  }else if(height < heightMin){
    setVh(vh + ((heightMin - height) / 2.4));
  }else{
    if((window.innerWidth * window.innerHeight) !== resolution.value){
      setVh(100);
    }
  }
};

const setVh = (vh: number) => {
  heightVh.value = `${vh}vh`;
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

</script>
